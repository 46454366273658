import { UseFrontendUser } from "../auth/server/frontend";
import { getOrganizationalChart } from "./data/organization";
import { subordinateEmployees, subordinatePositions } from "../organization";
import { getFullName } from "../string";

const hiddenOrganisation: string[] = [
    // Live
    "b0e19eef-a015-4588-9c38-f8b6bfbc24b3",
    "8dec1889-b69f-4af4-bd1c-acd65dc3ff2c",

    // Integration
    "f5f88450-ed22-4225-9687-3a395f250287",
    "550a3892-93ee-4f83-ba9e-8c3429392abc",
];

const hiddenPositions: string[] = [];

const hiddenEmployees: string[] = [
    // Live
    "c251e3ae-9bf6-449a-a551-0b0b6a9c2d4a",
    "29314f4d-e3f4-40d7-89e0-983e36f5a44c",

    // Integration
    "87af5cbb-2109-4146-8389-da5206812bc9",
    "a9861da2-61a6-4810-8eb9-a397c70859b4",
];

export const getStaticPageOrganization = async () => {
    const token = await UseFrontendUser();
    const organization = await getOrganizationalChart(token);

    const orgEmployees = organization.orgChartEmployees.filter(employee =>
        hiddenEmployees.every(id => id !== employee.id)
    );

    const orgPositions = organization.orgChartPositions
        .filter(position => hiddenPositions.every(id => id !== position.id))
        .map(position => ({
            ...position,
            employees:
                position?.employees?.filter(employee =>
                    hiddenEmployees.every(id => id !== employee.id)
                ) ?? [],
        }));

    const orgOganisations = organization.orgChartOrganisations
        .filter(organisation => hiddenOrganisation.every(id => id !== organisation.id))
        .map(organisation => ({
            ...organisation,
            positions:
                organisation?.positions
                    ?.filter(position => hiddenPositions.every(id => id !== position.id))
                    ?.map(position => ({
                        ...position,
                        employees:
                            position?.employees?.filter(employee =>
                                hiddenEmployees.every(id => id !== employee.id)
                            ) ?? [],
                    })) ?? [],
        }));

    const employees = orgEmployees;
    const positions = subordinateEmployees(orgPositions, orgEmployees);
    const departments = subordinatePositions(orgOganisations, positions);

    return {
        employees,
        departments,
        positions,
    };
};

export const filterOrganizationByStr = (
    str: string,
    organization: Organization.Index
): Organization.Index => {
    const employees = organization.employees.filter(employee => {
        return getFullName(employee).toLowerCase().includes(str.toLowerCase());
    });

    const departments = organization.departments.filter(department => {
        return department.designation.toLowerCase().includes(str.toLowerCase());
    });

    return {
        employees: employees.slice(0, 10),
        departments: departments.slice(0, 10),
        positions: [],
    };
};
